import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import InterviewListItem from "./InterviewListItem"

const InterviewList = ({ items, hideLastItemOnMobile = false }) => {
  return (
    <div className="container">
      <div className="flex flex-wrap -mx-3 lg:-mx-6">
        {items.map(item => (
          <div
            className={classNames("w-full", {
              "last:hidden lg:last:block": hideLastItemOnMobile,
            })}
            key={item.id}
          >
            <InterviewListItem {...item} />
          </div>
        ))}
      </div>
    </div>
  )
}

InterviewList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default InterviewList
