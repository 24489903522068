import { graphql } from "gatsby"
import React from "react"
import InterviewCards from "../components/InterviewCards"
import InterviewList from "../components/InterviewList";
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const InterviewsPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Interviews" description="Ruald Strydom" />

      <div className="bg-gray-100 py-12 lg:py-16">
        <div className="container py-1 lg:pb-1">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Interviews
          </h1>
        </div>
        {data.interviews && data.interviews.nodes.length > 0 ? (
          <InterviewList items={data.interviews.nodes} />
        ) : (
          <div className="container">No projects found.</div>
        )}
      </div>

    </Layout>
  )
}

export default InterviewsPage

export const query = graphql`
      query InterviewsQuery {
        interviews: allContentfulInterview(sort: {fields: name, order: ASC }) {
        nodes {
        ...InterviewCard
      }
    }
  }
      `
