import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const InterviewListItem = props => {
  const { name, slug, summary, thumbnail, emojis } = props

  return (
    <div className="bg-white h-full w-full shadow-sm rounded-md overflow-hidden group px-6 mt-3">
      <Link to={`/${slug}`}>
        <div className="flex items-center w-full">
          <div className="flex-none group-hover:opacity-75 transition duration-150 ease-in-out">
            <Img className="h-16 w-16 rounded-full" fluid={thumbnail.localFile.childImageSharp.fluid} alt={name} />
          </div>
          <div className="flex-auto p-4 sm:p-5">
            <h1 className="sm:text-lg text-gray-900 font-semibold">{name}</h1>
            <p className="text-sm sm:text-base text-gray-700">{summary}</p>
          </div>
          <div className="flex-auto text-right">
            <span role="img">
              {emojis}
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

InterviewListItem.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    localFile: PropTypes.object,
  }),
}

export default InterviewListItem

export const query = graphql`
  fragment InterviewCard on ContentfulInterview {
    id
    name
    slug
    thumbnail {
      localFile {
        childImageSharp {
          fluid(maxWidth: 444, maxHeight: 342, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    summary
    emojis
  }
`
